<template>
  <Toast />
  <ConfirmDialog />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Compras</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Recibir Orden de Compra</span>
    </li>
  </ul>
  <div class="col-12">
    <div v-if="!recibido && !cargandoDatos" class="surface-section px-4 py-2 md:px-12 lg:px-8">
      <div class="
            flex
            align-items-center
            flex-column
            lg:justify-content-center lg:flex-row
            mb-2
            mt-2
          ">
        <h3><strong>RECIBIR ORDEN DE COMPRA: #</strong> {{ ocId }}</h3>
      </div>
      <div class="grid bg-bluegray-100 p-2">
        <div class="field col-12 md:col-6">
          <p>
            <strong>NOMBRE PROVEEDOR: </strong>
            <span>{{ datosOc.nombre_proveedor }}</span>
          </p>
          <p>
            <strong>N° ORDEN COMPRA : </strong> <span>{{ ocId }}</span>
          </p>
          <p>
            <strong>USUARIO: </strong>
            <span>{{ datosOc.nombre_user }}</span>
          </p>
        </div>
        <div class="field col-12 md:col-6">
          <div class="field grid">
            <label for="sucursales" class="col-12 mb-2 md:col-4 md:mb-0"><strong>SUCURSAL :</strong>
            </label>
            <div class="col-12 md:col-8">
              <Dropdown v-model="sucursalSelect" :options="sucursales" optionLabel="nombre" optionValue="id"
                placeholder="Seleccione una sucursal" class="p-inputtext-sm" />
            </div>
            <small class="p-invalid text-red-500" v-if="errors.sucursalSelect">{{ errors.sucursalSelect[0] }}</small>
          </div>
          <div class="grid field">
            <label for="sucursales" class="col-12 mb-2 md:col-4 md:mb-0"><strong>FECHA RECEPCIÓN: </strong>
            </label>
            <div class="col-12 md:col-8">
              <Calendar v-model="fecha_recepcion" required="true" dateFormat="dd/mm/yy" class="p-inputtext-sm" disabled />
            </div>
          </div>
        </div>
      </div>
      <div class="grid mt-4 mb-4">
        <div class="field col-12 md:col-6">
          <label for="producto"><strong>SELECCIONE UN PRODUCTO: </strong>
          </label>
          <div class="p-inputgroup">
            <Dropdown v-model="productoSeleccionado" :options="grupoProductos" placeholder="Seleccione un producto"
              optionLabel="label" optionGroupLabel="label" optionGroupChildren="items">
            </Dropdown>
            <Button icon="pi pi-plus" class="
                  p-button-rounded p-button-info p-button-primary p-button-sm
                  ml-2
                " v-tooltip.top="'Agregar producto'" :disabled="!productoSeleccionado" @click="agregarProductoDetalle" />
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <label for="nro_recibo" class="ml-4"><strong>RECIBO/FACTURA: </strong>
          </label>
          <InputNumber v-model="nro_recibo" placeholder="N° RECIBO" class="p-inputtext-sm ml-4 mr-4" autofocus :min="0"
            :inputClass="'text-right'" :useGrouping="false" />
        </div>
        <div class="field col-12 md:col-3">
          <label for="descuento_oc" class="ml-4"><strong>DESCUENTO NETO: </strong>
          </label>
          <div class="p-inputgroup">
            <Checkbox class="ml-4 mt-1" value="si_tiene_descuento" v-model="si_tiene_descuento" />
            <InputNumber v-if="si_tiene_descuento == 'si_tiene_descuento'" v-model="descuento_oc" placeholder="DESCUENTO"
              class="p-inputtext-sm ml-4 mr-4" input-class="text-right" :minFractionDigits="2" locale="de-DE"
              :useGrouping="false" :min="0" :max="total_oc - 1" />
          </div>
        </div>
      </div>
      <div class="grid">
        <DataTable ref="dtOC" :value="productos" editMode="cell" @cell-edit-complete="onChangeEditandoCelda"
          class="editable-cells-table p-datatable-sm col-12" :rowHover="true" responsiveLayout="scroll">
          <Column class="white-space-nowrap" field="descripcion" header="DESCRICIÓN">
            {{ data.descripcion }}
          </Column>
          <Column class="text-right" field="pivot.precio" header="PRECIO COMPRA">
            <template #body="slotProps">
              {{ convertirNumeroGermanicFormat(slotProps.data.pivot.precio) }}
            </template>
            <template #editor="slotProps">
              <InputNumber v-model="slotProps.data.pivot.precio" autofocus :min="0" input-class="text-right"
                :minFractionDigits="2" locale="de-DE" />
            </template>
          </Column>
          <Column class="text-right" field="pivot.cantidad" header="CANTIDAD">
            <template #body="slotProps">
              {{ convertirNumeroGermanicFormat(slotProps.data.pivot.cantidad) }}
            </template>
            <template #editor="{ data }">
              <InputNumber :inputClass="'text-right'" :min="0" :minFractionDigits="2" locale="de-ED"
                v-model="data.pivot.cantidad" autofocus />
            </template>
          </Column>
          <Column class="text-right" field="pivot.margen" header="MARGEN UNITARIO">
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.pivot.margen) }}
            </template>
            <template #editor="{ data }">
              <InputNumber :inputClass="'text-right'" :min="0" :minFractionDigits="2" locale="de-ED"
                v-model="data.pivot.margen" autofocus />
            </template>
          </Column>
          <Column class="text-right" field="pivot.margen_porcentaje" header="MARGEN %">
            <template #body="{ data }">
              {{
                convertirNumeroGermanicFormat(data.pivot.margen_porcentaje) +
                " %"
              }}
            </template>
            <template #editor="{ data }">
              <InputNumber :inputClass="'text-right'" :min="0" :minFractionDigits="2" locale="de-ED"
                v-model="data.pivot.margen_porcentaje" autofocus />
            </template>
          </Column>
          <Column class="text-right" field="pivot.precio_venta" header="PRECIO VENTA">
            <template #body="slotProps">
              {{
                convertirNumeroGermanicFormat(slotProps.data.pivot.precio_venta)
              }}
            </template>
          </Column>
          <Column class="text-right" header="TOTAL">
            <template #body="slotProps">
              {{
                convertirNumeroGermanicFormat(
                  slotProps.data.pivot.precio * slotProps.data.pivot.cantidad
                )
              }}
            </template>
          </Column>
          <Column>
            <template #header>
              <i class="pi pi-cog"></i>
              ACCIONES
            </template>
            <template #body="slotProps">
              <div class="flex">
                <Button class="p-button-rounded p-button-text" icon="pi pi-clock"
                  v-tooltip.top="'Agregar Fecha Vencimiento'" @click="agregarFechaVencimiento(slotProps.data)" />
                <Button @click="quitar(slotProps)" v-tooltip.top="'Quitar Producto'" icon="pi pi-times"
                  class="p-button-rounded p-button-danger p-button-text" />
              </div>
            </template>
          </Column>
          <template #footer>
            <div class="text-right">
              <h5>
                <strong>Subtotal : </strong>
                {{ convertirNumeroGermanicFormat(total_oc) }} Bs.
              </h5>
            </div>
            <div class="text-right" v-if="si_tiene_descuento == 'si_tiene_descuento'">
              <h5>
                <strong>Descuento :</strong>
                {{ convertirNumeroGermanicFormat(descuento_oc) }} Bs.
              </h5>
            </div>
            <div class="text-right">
              <h5>
                <strong>Total a Pagar :</strong>
                {{ convertirNumeroGermanicFormat(total_oc - descuento_oc) }}
                Bs.
              </h5>
            </div>
            <div class="text-right">
              <h5 v-if="tipo_pagoSelect == 2">
                <strong>A Cuenta :</strong>
                {{ convertirNumeroGermanicFormat(a_cuenta) }} Bs.
              </h5>
            </div>
            <div class="text-right">
              <h5 v-if="tipo_pagoSelect == 2">
                <strong>Saldo :</strong>
                {{
                  convertirNumeroGermanicFormat(
                    total_oc - descuento_oc - a_cuenta
                  )
                }}
                Bs.
              </h5>
            </div>
          </template>
        </DataTable>
      </div>
      <div class="row mt-2">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-4">
            <label for="tipo_pago"><strong>SELECCIONE TIPO DE PAGO:
                <small v-if="tipo_pagoSelect == 2" class="text-red-500">
                  PAGO AL CR&Eacute;DITO
                </small></strong></label>
            <Dropdown id="tipo_pago" v-model="tipo_pagoSelect" :options="tipo_pagos" optionLabel="nombre"
              optionValue="id" />
          </div>
          <div class="field col-12 md:col-2">
            <label v-if="tipo_pagoSelect == 2" for="dias_credito"><strong>D&Iacute;AS CR&Eacute;DITO:</strong></label>
            <InputNumber v-if="tipo_pagoSelect == 2" v-model="dias_credito" :min="1" :max="30" :inputClass="'text-right'"
              :minFractionDigits="0" locale="de-ED" :useGrouping="false" showButtons />
          </div>
          <div class="field col-12 md:col-2">
            <label v-if="tipo_pagoSelect == 2" for="a_cuenta"><strong>A CUENTA:</strong></label>
            <InputNumber v-if="tipo_pagoSelect == 2" v-model="a_cuenta" :min="0" :max="total_oc - descuento_oc"
              :inputClass="'text-right'" :minFractionDigits="2" locale="de-ED" :useGrouping="false" showButtons />
          </div>
          <div class="field col-12 md:col-4">
            <label for=""><strong>OBSERVACIONES:</strong></label>
            <Textarea id="observacion" v-model="observacion" required="true" rows="1" cols="3" placeholder="Observaciones"
              @keyup.enter="recibirCompra">
              </Textarea>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="
              flex
              col-12
              align-items-end
              flex-column
              lg:justify-content-end lg:flex-row
            ">
          <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary mr-2 p-button-lg" @click="goBack">
          </Button>
          <Button label="Guardar" icon="pi pi-check" class="p-button-primary p-button-lg" @click="recibirCompra"
            :loading="enviando" :disabled="enviando" />
        </div>
      </div>
    </div>
    <div class="grid" v-else>
      <div v-if="cargandoDatos" class="col-12">
        <div class="flex align-items-center justify-content-center">
          <ProgressSpinner />
        </div>
      </div>
      <Message v-else class="col-8" :severity="'info'" :closable="false">
        <div class="flex align-items-center justify-content-center">
          <span> Orden de Compra Recibida. </span>
          <Button label="Listado de Ordenes de Compra" class="p-button-rounded p-button-info p-button-sm ml-2"
            @click="this.$router.push({ name: 'compras' })" />
          <Button label="Ver Recepcion OC" class="p-button-rounded p-button-info p-button-sm ml-2"
            @click="this.$router.push(`/orden_compra/${datosOc.id}/detalle`)" />
        </div>
      </Message>
    </div>
  </div>
  <FechaVence :show="nuevaFechaVence" :producto="productoFechas" @closeModal="closeModal" />
</template>

<script>
import OrdenCompraService from "@/service/OrdenCompraService";
import OCRecepcionService from "@/service/OCRecepcionService.js";
import SucursalService from "@/service/SucursalService.js";
import ProveedorService from "@/service/ProveedorService.js";
import FechaVence from "@/module/compras/FechaVence.vue";
import TipoPagoService from "@/service/TipoPagoService.js";

import { round } from "lodash";
import { includes } from "lodash";
export default {
  components: {
    FechaVence,
  },
  data() {
    return {
      si_tiene_descuento: false,
      ocId: this.$route.params.id,
      datosOc: {},
      productos: [],
      productoFechas: {},
      sucursalSelect: null,
      sucursales: [],
      errors: {},
      total_oc: 0,
      fecha_recepcion: null,
      enviando: false,
      recibido: false,
      cargandoDatos: true,
      grupoProductos: [],
      productoSeleccionado: null,
      nuevaFechaVence: false,
      nro_recibo: null,
      descuento_oc: 0,
      observacion: null,
      dias_credito: 0,
      a_cuenta: 0,
      tipo_pagoSelect: 1,
      tipo_pagos: [],
      precio_venta_producto: null,
    };
  },
  ordenCompraService: null,
  ocRecepcionService: null,
  sucursalService: null,
  proveedorService: null,
  tipoPagoService: null,
  created() {
    this.ordenCompraService = new OrdenCompraService();
    this.ocRecepcionService = new OCRecepcionService();
    this.sucursalService = new SucursalService();
    this.proveedorService = new ProveedorService();
    this.tipoPagoService = new TipoPagoService();
  },
  mounted() {
    this.fecha_recepcion = new Date();
    this.cargarSucursales();
    this.obtenerDatosOc();
    this.obtenerTiposPagos();
  },
  methods: {
    obtenerTiposPagos() {
      this.tipoPagoService.getAllTipoPagos().then((response) => {
        this.tipo_pagos = response;
      });
    },
    closeModal() {
      this.nuevaFechaVence = false;
    },
    agregarFechaVencimiento(producto) {
      this.productoFechas = producto;
      this.nuevaFechaVence = true;
    },
    agregarProductoDetalle() {
      if (this.verificarProductoLista()) {
        return false;
      }

      this.ordenCompraService
        .getprecio_compra_producto(this.productoSeleccionado.value)
        .then((response) => {
          let producto = {
            id: this.productoSeleccionado.value,
            codigo_barra: this.productoSeleccionado.data.codigo_barra,
            descripcion: this.productoSeleccionado.label,
            fechas_vencimiento: [],
            pivot: {
              producto_id: this.productoSeleccionado.value,
              cantidad: 0,
              precio: parseFloat(response.precio_compra_producto),
              margen: 0,
              margen_porcentaje: 25,
              precio_venta: 0,
            },
          };
          this.productos.push(producto);
        });
    },
    verificarProductoLista() {
      let producto = this.productos.find((producto) => {
        return producto.id == this.productoSeleccionado.value;
      });

      if (producto) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "El producto ya se encuentra en la lista",
          life: 3000,
        });
        return true;
      }
      return false;
    },
    quitar(producto) {
      this.$confirm.require({
        message: `¿Está seguro de quitar el producto ${producto.data.descripcion} del recibo de compra?`,
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.productos.splice(producto.index, 1);
          this.calcular_total();
        },
      });
    },
    obtenerDatosOc() {
      this.ordenCompraService
        .obtenerOrdenCompra(this.ocId)
        .then((response) => {
          this.datosOc = response.oc;
          this.productos = response.productos;

          if (this.datosOc.estado != 1) {
            this.recibido = true;
          }

          this.productos.forEach((producto) => {
            // me conecto al servicio y obtengo el margen_porcentaje del producto
            this.ocRecepcionService
              .getprecio_venta_producto(producto.id)
              .then((response) => {
                this.getprecio_venta_producto = response.precio_venta_producto;

                producto.fechas_vencimiento = [];
                producto.pivot.precio = parseFloat(producto.pivot.precio);
                producto.pivot.cantidad = parseFloat(producto.pivot.cantidad);
                producto.pivot.margen = 0;
                producto.pivot.margen_porcentaje =
                  response.precio_venta_producto;
                producto.pivot.precio_venta = parseFloat(producto.pivot.precio);
                this.calcularMargen(producto);
                this.calcularPrecioVenta(producto);
              });
          });

          this.cargarProductosProveedor();
          this.calcular_total();
        })
        .catch((error) => console.log(error));
    },

    cargarProductosProveedor() {
      this.proveedorService
        .obtenerLaboratorios(this.datosOc.proveedor_id)
        .then((response) => {
          this.grupoProductos = [];
          this.laboratorios = response.fabricas;

          this.laboratorios.forEach((laboratorio) => {
            let items = [];

            laboratorio.productos.forEach((producto) => {
              let item = {
                label: producto.descripcion,
                value: producto.id,
                data: producto,
              };
              items.push(item);
              this.cantidadProductosProveedor++;
            });

            let tmp = {
              label: laboratorio.nombre,
              code: laboratorio.id,
              items: items,
            };
            this.grupoProductos.push(tmp);
          });

          this.cargandoDatos = false;
        })
        .catch((error) => {
          console.log(error);
          this.cargandoDatos = false;
        });
    },
    calcular_total() {
      this.total_oc = 0;
      this.productos.forEach((item) => {
        this.total_oc += item.pivot.cantidad * item.pivot.precio;
      });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    verificarRecepcion() {
      let resultado = false;

      if (this.sucursalSelect == null) {
        this.$toast.add({
          severity: "error",
          summary: "Sucursal!",
          detail: "Debe seleccionar una sucursal de ingreso de mercaderia",
          life: 3000,
        });
        return resultado;
      }
      if (this.productos.length == 0) {
        this.$toast.add({
          severity: "error",
          summary: "Sucursal!",
          detail: "No existen productos para recibir",
          life: 3000,
        });
        return resultado;
      }

      if (this.fecha_recepcion == null) {
        this.$toast.add({
          severity: "error",
          summary: "Sucursal!",
          detail: "Debe seleccionar una fecha de recepcion",
          life: 3000,
        });
        return resultado;
      }

      resultado = this.productos.every((producto) => {
        if (producto.pivot.cantidad == 0) {
          this.$toast.add({
            severity: "error",
            summary: "Cantidad!",
            detail: `El producto ${producto.descripcion} no tiene cantidad`,
            life: 3000,
          });
          return;
        }
        if (producto.pivot.precio == 0) {
          this.$toast.add({
            severity: "error",
            summary: "Precio!",
            detail: `El producto ${producto.descripcion} no tiene precio`,
            life: 3000,
          });
          return;
        }
        return true;
      });

      return resultado;
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((response) => {
          this.sucursales = response;
          this.sucursales.forEach((sucursal) => {
            this.sucursalSelect = sucursal.id;
          });
        })
        .catch((error) => console.log(error));
    },
    recibirCompra() {
      this.enviando = true;

      if (!this.verificarRecepcion()) {
        this.enviando = false;
        return false;
      }
      // verificamos si los productos tienen fechas de vencimiento y preguntamos si desea recibir sin fechas de vencimiento o no
      let productosSinFechaVencimiento = this.productos.filter(
        (producto) => producto.fechas_vencimiento.length == 0
      );

      if (productosSinFechaVencimiento.length > 0) {
        this.$confirm.require({
          message: `¿Está seguro de recibir la orden de compra sin fechas de vencimiento?`,
          header: "Confirmación",
          icon: "pi pi-exclamation-triangle",
          acceptIcon: "pi pi-check",
          rejectIcon: "pi pi-times",
          accept: () => {
            let datos = {
              oc_id: this.ocId ? this.ocId : null,
              sucursal_id: this.sucursalSelect ? this.sucursalSelect : null,
              productos: this.productos ? this.productos : null,
              fecha_recepcion: this.fecha_recepcion,
              nro_recibo: this.nro_recibo,
              descuento_oc: this.descuento_oc,
              observacion: this.observacion,
              tipo_pago_id: this.tipo_pagoSelect,
              dias_credito: this.dias_credito,
              a_cuenta: this.a_cuenta,
            };
            this.ocRecepcionService.registrarReciboOc(datos).then(() => {
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: "Orden de Compra Recibida",
                life: 3000,
              });
              this.$router.push("/compras");
              this.enviando = false;
            });
          },
          reject: () => {
            this.enviando = false;
          },
        });
      } else {
        let datos = {
          oc_id: this.ocId,
          sucursal_id: this.sucursalSelect,
          productos: this.productos,
          fecha_recepcion: this.fecha_recepcion,
          nro_recibo: this.nro_recibo,
          descuento_oc: this.descuento_oc,
          observacion: this.observacion,
          tipo_pago_id: this.tipo_pagoSelect,
          dias_credito: this.dias_credito,
          a_cuenta: this.a_cuenta,
        };
        this.ocRecepcionService
          .registrarReciboOc(datos)
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: "Orden de Compra Recibida",
              life: 3000,
            });
            this.$router.push("/compras");
            this.enviando = false;
          })
          .catch((error) => {
            console.log(error);
            this.enviando = false;
          });
      }
    },
    validarCantidad(data) {
      if (data.pivot.cantidad <= 0 || data.pivot.cantidad == "") {
        this.$toast.add({
          severity: "error",
          summary: "Cantidad!",
          detail: `El producto ${data.descripcion} no tiene cantidad`,
          life: 3000,
        });
        data.pivot.cantidad = 1;
      } else
        if (data.pivot.cantidad > data.stock_maximo && data.stock_maximo > 0) {
          this.$toast.add({
            severity: "error",
            summary: "Stock Maximo!",
            detail: `El producto ${data.descripcion} supera el stock maximo ${data.stock_maximo}`,
            life: 3000,
          });
          data.pivot.cantidad = 1;
        }
      // vefico el precio de compra  que no sea 0
      if (data.pivot.precio == 0) {
        this.$toast.add({
          severity: "error",
          summary: "Precio!",
          detail: `El producto ${data.descripcion} no tiene precio`,
          life: 3000,
        });
        data.pivot.precio = 0;
      }
    },
    onChangeEditandoCelda(e) {
      let { data, newValue, field } = e;
      data[field] = newValue;

      // validar cantidad 
      this.validarCantidad(data);

      if (
        data.pivot.precio !== 0 &&
        includes(["pivot.precio", "pivot.cantidad"], field)
      ) {
        this.calcular_total();
        this.calcularPorcentajeMargen(data);
        this.calcularMargen(data);
        this.calcularPrecioVenta(data);
      }

      if (
        data.pivot.precio !== 0 &&
        includes(["pivot.margen_porcentaje"], field)
      ) {
        this.calcularMargen(data);
        this.calcularPrecioVenta(data);
      }
      if (data.pivot.precio !== 0 && includes(["pivot.margen"], field)) {
        this.calcularPorcentajeMargen(data);
        this.calcularPrecioVenta(data);
      }
    },

    calcularPorcentajeMargen(producto) {
      producto.pivot.margen_porcentaje = round(
        (producto.pivot.margen * 100) / producto.pivot.precio,
        2
      );
    },
    calcularMargen(producto) {
      producto.pivot.margen = round(
        (producto.pivot.margen_porcentaje * producto.pivot.precio) / 100,
        2
      );
    },
    calcularPrecioVenta(producto) {
      producto.pivot.precio_venta =
        producto.pivot.precio + producto.pivot.margen;
    },

    goBack() {
      this.$router.go(-1);
    },
    fechaActual() {
      return new Date().toLocaleDateString();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
